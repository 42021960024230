import { color_grey_dark, color_red_default, color_transparent, color_white } from "../../../../constants/colors";
import { NewFormTextInput } from "../../../inputs/FormTextInput/FormTextInput";
import { Button } from "../../../controls/Button/Button";
import React, { useEffect, useState, useCallback } from "react";
import { styles } from "./Styles";
import { LoadingContainer } from "../../../containers/LoadingContainer";
import { useDispatch, useSelector } from "react-redux";
import { updateUser } from "../../../../store/UserSlice";
import { FormProvider, useForm } from "react-hook-form";
import axios from "axios";
import Config from "../../../../Config";
import { getMyId } from "../../../../pages/personal/PersonalPage";
import { FormPhoneInput } from "../../../inputs/FormTextInput/FormPhoneInput";
import FormDefault from "../../../forms/authorization/FormDefault";
import { OnestNormalDefault, OnestNormalSmall } from "../../../styled/TextComponents";

import moment from "moment-timezone";

class User {
    constructor(image, name, email, phoneNumber, timeZone, total_lessons, balance, nextLessonIn, lessonsList) {
        this.image = image
        this.name = name
        this.email = email
        this.phoneNumber = phoneNumber
        this.timeZone = timeZone
        this.newPassword = null
        this.newPasswordRepeat = null
        this.total_lessons = total_lessons
        this.balance = balance
        this.nextLessonIn = nextLessonIn
        this.lessonsList = lessonsList
    }
}

export async function get_user() {
    let response = await axios.get(Config.BACKEND_ADDR + "/auth/me")
    let userData = response.data


    let lessons = userData.lessons.map(el => {
        return {
            student_id: el.student_id,
            studentName: el.student_name,
            time: el.time,
            payedLessons: el.payed_lessons,
            weekday: el.weekday
        }
    })
    console.log("userDataRaw", userData)
    return {
        id: userData.id,
        image: userData.image ? userData.image : process.env.PUBLIC_URL + "/no_profile_icon.png",
        name: userData.full_name,
        email: userData.email,
        phoneNumber: userData.phone_number,
        timeZone: "+3",
        newPassword: null,
        newPasswordRepeat: null,
        total_lessons: userData.total_lessons,
        balance: userData.balance,
        nextLessonIn: userData.next_lesson_in,
        lessonsList: lessons,
        endOfSubscriptionTime: userData.paid_until,
        time_zone: userData.time_zone,
    }
}


export const ProfileDataUpdatingBlock = ({ onRequestClose }) => {

    // Получение списка всех часовых поясов
    const timeZones = moment.tz.names();

    const user = useSelector(state => state.user);
    const dispatch = useDispatch();

    const [isLoading, setIsLoading] = useState(false);
    const [isNeedUpdate, setIsNeedUpdate] = useState(false);
    const [name, setName] = useState(user?.name || "");
    const [phone, setPhone] = useState(user?.phoneNumber || "");
    const [timeZone, setTimeZone] = useState(user?.time_zone || Intl.DateTimeFormat().resolvedOptions().timeZone);
    const [newPassword, setNewPassword] = useState("");
    const [newPasswordRepeat, setNewPasswordRepeat] = useState("");

    const handleNameChange = useCallback((e) => {
        setName(e.target.value);
        setIsNeedUpdate(true);
    }, [name]);

    const handlePhoneChange = useCallback((e) => {

        setPhone(e.target.value);
        setIsNeedUpdate(true);
    }, [phone]);

    const handleTimeZoneChange = useCallback((e) => {
        setTimeZone(e.target.value);
        setIsNeedUpdate(true);
    }, [timeZone]);

    const handleNewPasswordChange = useCallback((e) => {
        setNewPassword(e.target.value);
        setIsNeedUpdate(true);
    }, [newPassword]);

    const handleNewPasswordRepeatChange = useCallback((e) => {
        setNewPasswordRepeat(e.target.value);
        setIsNeedUpdate(true);
    }, [newPasswordRepeat]);

    function uploadNewData() {
        setIsLoading(true)

        let oldState = { ...user }
        dispatch(updateUser({
            name: name,
            image: user.image,
            phone: phone,
            time_zone: timeZone,
        }))
        getMyId().then(id => {
            let requestData = {
                full_name: name,
                image: user.image,
                phone_number: phone,
                time_zone: timeZone,
            }
/* 
            if (newPassword.length > 0) {
                requestData.password = newPassword
            }
*/
            axios.patch(Config.BACKEND_ADDR + `/auth/user/${id}`, requestData).then(
                response => {
                    setIsNeedUpdate(false)
                    console.log("RESPONSE", response)
                    alert("Данные успешно обновлены")
                })
                .catch(() => {
                    alert("Ошибка обновления данных")
                    dispatch(updateUser(oldState))
                })
        }).catch(() => {
            alert("Ошибка обновления данных")
            dispatch(updateUser(oldState))
        })
        onRequestClose()

        setIsLoading(false)
    }

    return (
        <div style={{ ...styles.dataUpdatingContainer }}>
            <LoadingContainer isLoading={isLoading}>
                <div
                    width={410}
                    style={styles.dataUpdatingForm}
                >
                    <NewFormTextInput
                        placeholder={"Введите имя"}
                        type={"text"}
                        title={"Имя"}
                        value={name}
                        onChange={handleNameChange}
                    />
                    <FormPhoneInput
                        placeholder={user.phoneNumber}
                        type={"tel"}
                        title={"Номер телефона"}
                        value={phone}
                        onChange={handlePhoneChange}
                    />

                    <OnestNormalDefault weight={"normal"}>
                        <div
                            style={{
                                display: "inline-flex",
                                flexDirection: "column",
                                alignItems: "flex-start",
                                width: "100%",
                                gap: 10,
                                fontSize: 14
                            }}
                        >
                            <OnestNormalSmall>{"Часовой пояс"}</OnestNormalSmall>
                            <OnestNormalDefault>

                                <select
                                    value={timeZone}
                                    onChange={handleTimeZoneChange}
                                    style={{
                                        width: "350px",
                                        height: "50px",
                                        borderRadius: "5px",
                                        border: "1px solid transparent",
                                        backgroundColor: "#F5F5F5",
                                        outline: "none",
                                    }}
                                >
                                    {timeZones.map((zone) => (
                                        <option key={zone} value={zone}>
                                            {zone}
                                        </option>
                                    ))}
                                </select>
                            </OnestNormalDefault>
                        </div>
                    </OnestNormalDefault>
                    {/* 
                        <NewFormTextInput 
                            placeholder={"Введите новый пароль"} 
                            type={"password"} 
                            title={"Введите новый пароль"} 
                            value={newPassword} 
                            onChange={handleNewPasswordChange} 
                        />
                        <NewFormTextInput 
                            placeholder={"Повторите новый пароль"} 
                            type={"password"} 
                            title={"Повторите новый пароль"} 
                            value={newPasswordRepeat} 
                            onChange={handleNewPasswordRepeatChange} 
                        />
                         */}
                    <Button
                        outline={false}
                        type="submit"
                        width="80%"
                        height={60}
                        backgroundColor={isNeedUpdate ? color_red_default : color_grey_dark}
                        color={color_white}
                        borderColor={color_transparent}
                        fontSize={18}
                        disabled={!isNeedUpdate}
                        onClick={uploadNewData}
                    >Сохранить</Button>
                </div>
            </LoadingContainer>
        </div>
    )
}